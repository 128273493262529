import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {variables} from './Variables.js';

const Login = () => {

    const [username, usernameUpdate] = useState('');
    const [password, passwordUpdate] = useState('');
    const logoURL = "https://betronka.com/____impro/1/onewebmedia/Logo%20Betronka.png?etag=%228a61-63b6e1f0%22&sourceContentType=image%2Fpng&ignoreAspectRatio&resize=426%2B154";
    const navigate = useNavigate();
    const baseUrl=variables.serverURL+"user/Authenticate";
    //https://localhost:44320/user/Authenticate
    useEffect(() => {
        sessionStorage.clear();
    }, [])

    const ProceedLoginUsingAPI = (e) => {
        e.preventDefault();
        if (validate()) {
            ///implementation
            //console.log('proceed'); 
            let inputobj = { "username": username, "password": password };

            let jsonObj = JSON.stringify(inputobj);

            fetch(baseUrl, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: jsonObj
            }).then(res =>
                //return res.json();
                res.text()
            ).then((resp) => {
                //console.log("success! jwtoken:")
                //console.log(resp);
                
                if(resp.charAt(0) === "{") 
                { }
                else {
                
                toast.success('Welcome back!');
                sessionStorage.setItem('username', username);
                sessionStorage.setItem('jwttoken', resp);
                navigate('/home')
            }
                //if (Object.keys(resp).length === 0) {
                //    toast.error('Please Enter valid username');
                //} else {
                //    if (resp.password === password) {
                //        toast.success('Success');
                //        sessionStorage.setItem('username', username);
                //        navigate('/')
                //    } else {
                //        toast.error('Please Enter valid credentials');
                //    }
                //}
            }).catch((err) => {
                //toast.error('Login Failed due to : ' + err.message);
            })

        }
    }

    const validate = () => {
        let result = true;
        if (username === '' || username === null) {
            result = false;
            toast.warning('Please Enter Username');
        }

        if (password === '' || password === null) {
            result = false;
            toast.warning('Please Enter Password');
        }

        return result;
    }

    return (
        <div className="row">
            <div className="col-lg-6 offset-lg-3" style={{ marginTop: '100px' }}>
                <form onSubmit={ProceedLoginUsingAPI} className="container">
                    <div className="card">
                        <div className="card-header text-primary">
                            <h2>User Login</h2>
                        </div>
                        <div className="card-body">
                            <div className="form-group" >
                                <label>User Name <span className="errmsg">*</span></label>
                                <input value={username} onChange={e => usernameUpdate(e.target.value)} className="form-control"></input>
                            </div>
                            <div className="form-group" >
                                <label>Password <span className="errmsg">*</span></label>
                                <input value={password} onChange={e => passwordUpdate(e.target.value)} type="password" className="form-control"></input>
                            </div>
                        </div>

                        <div className="card-footer text-center">
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary" >Login</button>
                                <span>    </span>
                                <Link className="btn btn-success" to={'/register'}>New User</Link>
                            </div>
                        </div>

                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                        <img width="213" height="100" src={logoURL} alt="Betronka" />
                    </div>

                </form>

            </div>

        </div>

    );
}

export default Login;