//import React, { Component } from 'react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {NavMenu} from './NavMenu'
import NavSubMenu from './NavSubMenu'
import NavSubMenu2D from './NavSubMenu2D'

import useFetch from "./useFetch";
import Plot from 'react-plotly.js';
import { variables } from './Variables.js';


//export class Home extends Component {
export const HomeSagWeek = (props) => {
  //static displayName = Home.name;

  //const baseUrl="https://cloud-betronka.com/usuarios";
  //const [form, setForm]=useState({username:'', password:''});

  const navigate = useNavigate();
  var myDate, myNllenado, myBlevel;
  //const baseUrl=variables.serverURL+"user/Authenticate";
  const baseUrl = variables.serverURL + "datasag/days/7";
  var myLayout = { title: "Histórico Sag: Nivel Llenado vs Nivel Bolas [1 Semana]", hovermode: false, xaxis: { title: "Fecha" }, yaxis: { title: "Llenado [%]", range: [0, 35] } };
  const myConfig = { displaylogo: false, displayModeBar: true };
  var myData = null;


  useEffect(() => {
    let username = sessionStorage.getItem('username');
    if (username === '' || username === null) {
      navigate('/');
      // } else {
      //    token = sessionStorage.getItem('jwttoken');
    }
  }, []);

  const { fetchdata, isLoaded, errorInfo } = useFetch(baseUrl);

  if (fetchdata === null) {
  } else {
    myDate = fetchdata.map(({ date }) => (date));
    myNllenado = fetchdata.map(({ nllenadomean }) => (nllenadomean));
    myBlevel = fetchdata.map(({ balllevel }) => (balllevel));

    myData = [
      { name: 'Llenado', x: myDate, y: myNllenado, type: "scattergl", mode: "markers", marker: { size: 3, color: "red" } },
      { name: 'Bolas', x: myDate, y: myBlevel, type: "scattergl", mode: "markers", marker: { size: 3, color: "blue" } }
    ];
  }


  return (
    <div>
      <NavMenu />
      <NavSubMenu />
      <NavSubMenu2D />
      <br />

      {/*  <h3 className='text-center'> Histórico Nivel Llenado vs Nivel Bolas [1 Semana]</h3> */}


      {/*<MyPlotSagMonth /> */}
      <div className="row">
        <div className="col-lg-8 offset-lg-2" style={{ marginTop: '10px' }}>
          {!fetchdata && <div className="card">
            <div className="card-header"></div>
            <div className="card-body">
              <div>
                <h4 className='text-center' > Please wait, loading data...</h4>
              </div>
              <div>

              </div>
            </div>
            <div className="card-footer"></div>

          </div>}
          {fetchdata && <Plot data={myData} layout={myLayout} frames={[]} config={myConfig} />}
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

////import React, { Component } from 'react';
//import React, {useState, useEffect} from 'react';
//import md5 from 'md5';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import Cookies from 'universal-cookie';
//import axios from 'axios';
//import { useNavigate } from 'react-router-dom';
////import {variables} from '../Variables.js';
//import NavSubMenu from './NavSubMenu'
//import NavSubMenu2D from './NavSubMenu2D'
//
////import { MyPlotSagWeek } from './MyPlotSagWeek';
//import { MyPlotSagWeek } from './MyPlotSagWeek';
////import './HomeSagWeek.css';
//
////export class Home extends Component {
//export const HomeSagWeek = (props) => {
//  //static displayName = Home.name;
//
////const baseUrl="https://cloud-betronka.com/usuarios";
//const cookies = new Cookies();
//const navigate = useNavigate();
//
//const [form, setForm]=useState({
//  username:'',
//  password: ''
//});
//
//
//
//
//  useEffect(()=>{
//    if(!cookies.get('id')){
//      navigate("/");
//    }
//  },[]);
//
//
//    return (
//      <div >
//        <NavSubMenu />
//        <NavSubMenu2D />
//        <br/>
//        <br/>
//        <h3> Histórico Nivel Llenado vs Nivel Bolas [1 Semana]</h3>
//
//        <br/>
//          <MyPlotSagWeek />
//
//      </div>
//    );
//  }
//