//import React, { Component } from 'react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {NavMenu} from './NavMenu'
import NavSubMenu from './NavSubMenu'
import NavSubMenu3DT from './NavSubMenu3DT'

import useFetch from "./useFetch";
import Plot from 'react-plotly.js';
import { variables } from './Variables.js';

//export class Home extends Component {
export const HomeSag3DTThreeMonths = (props) => {
  //static displayName = Home.name;

  //const baseUrl="https://cloud-betronka.com/usuarios";
  //const [form, setForm]=useState({ username:'', password: ''});

  const navigate = useNavigate();
  var myDate, myNllenado, myBlevel;
  //const baseUrl=variables.serverURL+"user/Authenticate";
  const baseUrl = variables.serverURL + "datasag/llenado/threemonths";
  //var myLayout = { title: "3D Sag: RPM vs Nivel Llenado vs Nivel Bolas [1 Semana]", hovermode: false, xaxis: { title: "Fecha" }, yaxis: { title: "Llenado [%]", range: [0, 35] } };
  
  var myLayout = {
    title: "3D Sag: Fecha vs Nivel Llenado vs Nivel Bolas [3 Meses]", hovermode: false,
    turntable: { direction: '2d', enabled: true, tilt: 30, rotation: [0, 0, 0, 0] },
    scene: {
      aspectmode: "manual", aspectratio: { x: 1, y: 1, z: 1 },
      xaxis: { title: "Fecha" }, yaxis: { title: "Llenado [%]", range: [0, 35] }, zaxis: { title: "Bolas [%]", range: [0, 35] }
    }
  };

  const myConfig = { displaylogo: false, displayModeBar: false };
  var myData = null;

  useEffect(() => {
    let username = sessionStorage.getItem('username');
    if (username === '' || username === null) {
      navigate('/');
      // } else {
      //    token = sessionStorage.getItem('jwttoken');
    }
  }, []);

  const { fetchdata, isLoaded, errorInfo } = useFetch(baseUrl);

  if (fetchdata === null) {
  } else {
    myDate = fetchdata.map(({ date }) => (date));
    myNllenado = fetchdata.map(({ nllenadomean }) => (nllenadomean));
    myBlevel = fetchdata.map(({ balllevel }) => (balllevel));

    myData = [
      { name: 'Nivel_Llenado', x: myDate, y: myNllenado, z: myBlevel, type: "scatter3d", mode: "markers", marker: { size: 1.5, color: "red" } }
    ];
  }


  return (
    <div>
      <NavMenu />
      <NavSubMenu />
      <NavSubMenu3DT />
      <br />

      {/*  <h3 className='text-center'> Histórico Nivel Llenado vs Nivel Bolas [1 Semana]</h3> */}


      {/*<MyPlotSagMonth /> */}
      <div className="row">
        <div className="col-lg-8 offset-lg-2" style={{ marginTop: '10px' }}>
          {!fetchdata && <div className="card">
            <div className="card-header"></div>
            <div className="card-body">
              <div>
                <h4 className='text-center' > Please wait, loading data...</h4>
              </div>
              <div>

              </div>
            </div>
            <div className="card-footer"></div>

          </div>}
          {fetchdata && <Plot data={myData} layout={myLayout} frames={[]} config={myConfig} />}
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

////import React, { Component } from 'react';
//import React, {useState, useEffect} from 'react';
//import md5 from 'md5';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import Cookies from 'universal-cookie';
//import axios from 'axios';
//import { useNavigate } from 'react-router-dom';
////import {variables} from '../Variables.js';
//import NavSubMenu from './NavSubMenu'
//import NavSubMenu3DT from './NavSubMenu3DT'
//import { MyPlotSag3DTThreeMonths } from './MyPlotSag3DTThreeMonths';
//
////export class Home extends Component {
//export const HomeSag3DTThreeMonths = (props) => {
//  //static displayName = Home.name;
//
////const baseUrl="https://cloud-betronka.com/usuarios";
//const cookies = new Cookies();
//const navigate = useNavigate();
//
//const [form, setForm]=useState({
//  username:'',
//  password: ''
//});
//  
//  useEffect(()=>{
//    if(!cookies.get('id')){
//      navigate("/");
//    }
//  },[]);
//
//
//    return (
//      <div>
//        <NavSubMenu />
//        <NavSubMenu3DT />
//        <br/>
//        <br/>
//        <h3> Fecha vs Nivel Llenado vs Nivel Bolas [3 Meses]</h3>
//        
//        <br/>
//        <MyPlotSag3DTThreeMonths/>
//      </div>
//    );
//  }
//