import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Register = () => {

    const [id, idChange] = useState("");
    const [name, nameChange] = useState("");
    const [password, passwordChange] = useState("");
    const [email, emailChange] = useState("");
    const [phone, phoneChange] = useState("");
    const [country, countryChange] = useState("chile");
    const [address, addressChange] = useState("");
    const [gender, genderChange] = useState("");
    const logoURL = "https://betronka.com/____impro/1/onewebmedia/Logo%20Betronka.png?etag=%228a61-63b6e1f0%22&sourceContentType=image%2Fpng&ignoreAspectRatio&resize=426%2B154";
    
    const navigate = useNavigate();
    const goBack = () =>{
        navigate('/');
    }
    const IsValidate = () => {
        let isproceed = true;
        let errormessage = 'Please enter the value in ';
        if (id == null || id == '') {
            isproceed = false;
            errormessage += ' Username';
        }
        if (name == null || name == '') {
            isproceed = false;
            errormessage += ' Fullname';
        }
        if (password == null || password == '') {
            isproceed = false;
            errormessage += ' Password';
        }
        if (email == null || email == '') {
            isproceed = false;
            errormessage += ' Email';
        }
        if (!isproceed) {
            toast.warning(errormessage)
        }else{
            if(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)){

            }else{
                isproceed=false;
                toast.warning('Please enter a valid email')
            }
        }

        return isproceed;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let regObj = { id, name, password, email, phone, country, address, gender };
        //console.log(regObj);
        if (IsValidate()) {

            fetch("http://localhost:8000/user", {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(regObj)
            }).then((res) => {
                toast.success('Registered succsessfully.')
                navigate('/login');
            }).catch((err) => {
                toast.error('Failed : ' + err.message);
            });
        }
    }

    return (
        <div>
            <div className="offset-lg-3 col-lg-6" style={{ marginTop: '100px' }}>
                <form className="container" onSubmit={handleSubmit}>
                    <div className="card">
                        <div className="card-header text-primary">
                            <h2> User Registration </h2>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>User Name <span className="errmsg">*</span></label>
                                        <input value={id} onChange={e => idChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Password <span className="errmsg">*</span></label>
                                        <input value={password} onChange={e => passwordChange(e.target.value)} type="password" className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Full Name <span className="errmsg">*</span></label>
                                        <input value={name} onChange={e => nameChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Email <span className="errmsg">*</span></label>
                                        <input value={email} onChange={e => emailChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Phone <span className="errmsg">*</span></label>
                                        <input value={phone} onChange={e => phoneChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Country <span className="errmsg">*</span></label>
                                        <select value={country} onChange={e => countryChange(e.target.value)} className="form-control">
                                            <option value="chile">Chile</option>
                                            <option value="usa">USA</option>
                                            <option value="netherlands">Netherlands</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <textarea value={address} onChange={e => addressChange(e.target.value)} className="form-control"></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Gender </label>
                                        <br></br>
                                        <input type="radio" checked={"gender" === 'male'} onChange={e => genderChange(e.target.value)} name="gender" value="male" className="app-check"></input>
                                        <label>Male</label>
                                        <input type="radio" checked={"gender" === 'female'} onChange={e => genderChange(e.target.value)} name="gender" value="female" className="app-check"></input>
                                        <label>Female</label>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="card-footer text-center">
                            <button type="submit" className="btn btn-primary">Register</button>
                            <span> </span>
                            <button className="btn btn-danger" onClick={goBack}>Go Back</button>
                        </div>

                    </div>
                    
                    <div className="text-center">
                        <img width="213" height="100" src={logoURL} alt="Betronka" />
                    </div>

                </form >



            </div >

        </div >
    );
}

export default Register;