import { useEffect,useState} from "react"
import { useNavigate } from "react-router-dom";
    
const useFetch = (url) => {

// usage: const {fetchdata,isLoaded,errorInfo} = useFetch(url);
// ex.    const {fetchdata,isLoaded,errorInfo} = useFetch('http://localhost:8000/company');

    const navigate = useNavigate();
    const [fetchdata, dataChange] = useState(null);
    const [isLoaded, ChangeLoad] = useState(null);
    const [errorInfo, SetError] = useState(null);
    const token = sessionStorage.getItem('jwttoken');

    useEffect(() => {
        let username = sessionStorage.getItem('username');
        if (username === '' || username === null) {
          navigate('/');
          // } else {
          //    token = sessionStorage.getItem('jwttoken');
        }
        fetch(url, {
            headers: { 'Authorization': 'bearer ' + token }
        }).then(res => {
            //console.log(res);
            if(!res.ok){
                throw Error('Failed to fetch the data');
            }
            return res.json();
        }).then(result => {
          setTimeout(() => {
            dataChange(result);
            ChangeLoad(false);
            }, 1000);
        }).catch(res => {
            SetError(res.message);
            ChangeLoad(false);
            navigate('/');
        })

    }, [url])

    return {fetchdata,isLoaded,errorInfo};

}

export default useFetch;