import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import  Login  from "./components/Login";
import  Register  from "./components/Register";

import { HomeSagDay } from "./components/HomeSagDay";
import { HomeSagWeek } from "./components/HomeSagWeek";
import { HomeSagMonth } from "./components/HomeSagMonth";
import { HomeSagThreeMonths } from "./components/HomeSagThreeMonths";
import { HomeSagSixMonths } from "./components/HomeSagSixMonths";

import { HomeSag3DTDay } from "./components/HomeSag3DTDay";
import { HomeSag3DTWeek } from "./components/HomeSag3DTWeek";
import { HomeSag3DTMonth } from "./components/HomeSag3DTMonth";
import { HomeSag3DTThreeMonths } from "./components/HomeSag3DTThreeMonths";

import { HomeSag3DRDay } from "./components/HomeSag3DRDay";
import { HomeSag3DRWeek } from "./components/HomeSag3DRWeek";
import { HomeSag3DRMonth } from "./components/HomeSag3DRMonth";
import { HomeSag3DRThreeMonths } from "./components/HomeSag3DRThreeMonths";

const AppRoutes = [
  {
    index: true,
    element: <Login />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
////////////////////////////////////////////////////////
  {
    path: '/plot-2d-1d',
    element: <HomeSagDay />
  },
  {
    path: '/plot-2d-1w',
    element: <HomeSagWeek />
  },
  {
    path: '/plot-2d-1m',
    element: <HomeSagMonth />
  },
  {
    path: '/plot-2d-3m',
    element: <HomeSagThreeMonths />
  },
  {
    path: '/plot-2d-6m',
    element: <HomeSagSixMonths />
  },
  ////////////////////////////////////////////////////////
  {
    path: '/plot-3dt-1d',
    element: <HomeSag3DTDay />
  },
  {
    path: '/plot-3dt-1w',
    element: <HomeSag3DTWeek />
  },
  {
    path: '/plot-3dt-1m',
    element: <HomeSag3DTMonth />
  },
  {
    path: '/plot-3dt-3m',
    element: <HomeSag3DTThreeMonths />
  },
  ////////////////////////////////////////////////////////
  {
    path: '/plot-3dr-1d',
    element: <HomeSag3DRDay />
  },
  {
    path: '/plot-3dr-1w',
    element: <HomeSag3DRWeek />
  },
  {
    path: '/plot-3dr-1m',
    element: <HomeSag3DRMonth />
  },
  {
    path: '/plot-3dr-3m',
    element: <HomeSag3DRThreeMonths />
  }
];

export default AppRoutes;
