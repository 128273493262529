import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import NavSubMenu from './NavSubMenu'
import {NavMenu} from './NavMenu'

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <NavMenu />
        <NavSubMenu />
        
        {/*<h2>Hola, {sessionStorage.getItem('username')}!</h2> */}
        <br/>
        <p>Bienvenido a tu CloudBetronka App, aquí puedes administrar tus servicios de Betronka en línea.</p>
        <br/>
        
        <p>En la barra de Navegación Principal tienes un par de opciones para comenzar:</p>
        <ul>
          <li><strong>Home</strong>, para volver aquí cuando te encuentres en otra página.</li>
          <li><strong>Register</strong>, para ir al sitio de registro y solicitar un nuevo usuario.</li>
          <li><strong>Login</strong>, para cuando desees cerrar la sesión actual y regresar al acceso.</li>
        </ul>
        
        <br/>
        <p>En la barra de Navegación Secundaria tienes un par de opciones para visualizar tus datos:</p>
        
        <ul>
          <li><strong>Histórico</strong>, para ver una comparativa de la evolución del nivel de Llenado y de Bolas en el tiempo.</li>
          <li><strong>3D (time)</strong>, para ver una comparativa 3D de la evolución del nivel de Llenado y de Bolas.</li>
          <li><strong>3D (rpm)</strong>, para ver una comparativa 3D del estado de nivel de Llenado y de Bolas c/r a las RPM.</li>
        </ul>
        <br/>
        <br/>
        
      </div>
    );
  }

}
